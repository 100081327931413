import validate from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45global from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/middleware/trailingSlash.global.ts";
import manifest_45route_45rule from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/middleware/auth.ts"),
  "kyc-check": () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/middleware/kycCheck.ts"),
  "manual-shipping": () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/middleware/manualShipping.ts"),
  onboarding: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/middleware/onboarding.ts")
}