// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class AnalyticsModule extends FetchFactory<IResponse> {
  getListCourier() {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/couriers`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getIncomeSummary(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/summary/income?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCheckoutSummary(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/summary/checkout?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getActivitySummary(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/transaction-activity?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCheckoutFunnel(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/funnel?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getPopularProduct(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/popular-products?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getLoyalCustomer(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/loyal-customers?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShipmentCities(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/shipments/cities?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShipmentCouriers(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/shipments/couriers?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCheckoutPayments(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/payments?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShippingTypes(query: { start_date: string; end_date: string }) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/seller-dash/analytics/shipping-types?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  // Analytics Shipping Start
  getShippingSummary(query: queryAnalyticsShipping) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/summary?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShippingFunnel(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/funnel?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getFailedSummary(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/failed-summary?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCourierPerformance(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/courier-performance?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getRTOAnalytics(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/rto?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDestinationCitySummary(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/delivery-distribution?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCourierPercentage(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/courier-distribution?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDeliveryType(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/delivery-type-distribution?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getPaymentMethod(query: {
    "time-period-start": string;
    "time-period-end": string;
    sources: string;
    "service-types": string;
    couriers: string;
  }) {
    const { FLIKSHIPPINGSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${FLIKSHIPPINGSVC}/v1/seller-dash/analytics/payment-type-distribution?${query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default AnalyticsModule;
