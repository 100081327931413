// 3rd's
import { type $Fetch, type FetchOptions } from "ofetch";

/*
 The HttpFactory acts as a wrapper around an HTTP client. 
 It encapsulates the functionality for making API requests asynchronously 
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory<T> {
  private $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns
   */
  call(
    method: string,
    url: string,
    data?: object,
    fetchOptions?: FetchOptions<"json">,
  ): Promise<T> {
    return this.$fetch<T>(url, {
      method,
      body: data,
      ...fetchOptions,
    });
  }

  async download(
    method: string,
    url: string,
    fetchOptions: FetchOptions<"blob">,
    defaultFilename: string,
  ): Promise<string> {
    let filename = "";
    const response = await this.$fetch.raw(url, {
      method: method,
      headers: fetchOptions?.headers,
    });
    if (response.status !== 200) {
      return Promise.reject(new Error("Could not download the file"));
    }
    const header = response.headers.get("Content-Disposition");
    const parts = header?.split(";") || [];
    if (parts.length > 0) {
      filename = parts[1].split("=")[1].replaceAll('"', "");
    } else {
      filename = defaultFilename;
    }

    const urlObject = window.URL.createObjectURL(new Blob([response._data]));
    const link = document.createElement("a");
    link.href = urlObject;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    return Promise.resolve("");
  }
}

export default FetchFactory;
