// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class ConfigModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getFlagFeature(key) {
    const { FF_URL } = useRuntimeConfig().public;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: FF_URL,
    };
    const { user } = useAuthStore();
    const payload = {
      defaultValue: false,
      user: {
        anonymous: user?.merchant_login_id !== undefined,
        key:
          user?.merchant_login_id !== undefined
            ? user.merchant_login_id
            : "guest",
        custom: {
          env_name: process.env.NODE_ENV !== "production" ? "dev" : "prod",
        },
      },
    };
    return this.call(
      "POST",
      `v1/feature/${key}/eval`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default ConfigModule;
