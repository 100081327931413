/* eslint-disable */
window.dataLayer = window.dataLayer || [];
// const gtag = function () {
//   dataLayer.push(arguments);
// };

// module.exports = ({ app }, inject) => {
//   inject("gtag", gtag);
// };
export default defineNuxtPlugin((nuxtApp) => {
  const gtag = function () {
    dataLayer.push(arguments);
  };
  // nuxtApp.vueApp.use(domtoimage)
  return {
    provide: {
      gtag,
    },
  };
});
