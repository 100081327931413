import Tracker from "@openreplay/tracker";
import { v4 as uid } from "uuid";
import { useAuthStore } from "@/stores/auth";
import { useMerchantStore } from "@/stores/merchant";

function defaultUserId() {
  return uid();
}
export default defineNuxtPlugin(() => {
  const { SHIPPING_OPENREPLAY_KEY, APP_DEBUG } = useRuntimeConfig().public;
  const { user, authenticated } = useAuthStore(); // use auth store
  const { profile } = useMerchantStore(); // use auth store
  const loggedIn = authenticated;
  const userId = user?.merchant_login_id
    ? user?.merchant_login_id
    : defaultUserId();
  const email = loggedIn ? user.email : "";
  const name = loggedIn ? profile?.brand_name : "";
  if (SHIPPING_OPENREPLAY_KEY !== "" && !("__OPENREPLAY__" in window)) {
    const trackerConfig = {
      projectKey: SHIPPING_OPENREPLAY_KEY,
      ingestPoint: "https://openreplay.useflik.com/ingest",
      __DISABLE_SECURE_MODE: APP_DEBUG,
      obscureTextNumbers: false,
      obscureTextEmails: false,
      capturePerformance: true,
      capturePageLoadTimings: true,
      onStart: ({ sessionID }: { sessionID: string }) =>
        console.log("OpenReplay tracker started with session: ", sessionID),
      network: {
        sessionTokenHeader: false,
        ignoreHeaders: [],
        capturePayload: true,
      },
    };

    console.log("openReplayTrack trackerConfig:", trackerConfig);
    const tracker = new Tracker(trackerConfig);
    console.log("tracker:", tracker);

    if (!tracker.isActive()) {
      tracker.setUserID(userId);
      tracker.start({
        userID: userId,
        metadata: {
          email: email,
          merchant_name: name,
        },
      });
    }
  }
});
