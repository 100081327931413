// 3rd's
import { type FetchOptions, ofetch } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

type PrintLabelPayload = {
  id: String;
  perpage: Number;
};

class ShippingModule extends FetchFactory<IResponse> {
  printLabel(payload: PrintLabelPayload): Promise<IResponse> {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions = {
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return ofetch
      .raw(
        `${config.API_URL}/flik-shipping/v1/seller-dash/shipments/${payload.id}/label?per-page=${payload.perpage}`,
        fetchOptions,
      )
      .then((res) => {
        const url = window.URL.createObjectURL(res._data);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${payload.id}-${payload.perpage}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
        return {
          success: true,
        };
      })
      .catch((e) => {
        return {
          success: false,
          error: `${e}`,
        };
      });
  }

  printLabelOrder(payload: PrintLabelPayload): Promise<IResponse> {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions = {
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return ofetch
      .raw(
        `${config.API_URL}/flik-shipping/v1/seller-dash/orders/${payload.id}/label?per-page=${payload.perpage}`,
        fetchOptions,
      )
      .then((res) => {
        const url = window.URL.createObjectURL(res._data);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${payload.id}-${payload.perpage}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
        return {
          success: true,
        };
      })
      .catch((e) => {
        return {
          success: false,
          error: `${e}`,
        };
      });
  }

  downloadInvoice(id: String) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions = {
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return ofetch
      .raw(
        `${config.API_URL}/flik-shipping/v1/seller-dash/shipments/${id}/invoice`,
        fetchOptions,
      )
      .then((res) => {
        const url = window.URL.createObjectURL(res._data);

        const link = document.createElement("a");
        link.href = url;
        link.download = `invoice-${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
        return {
          success: true,
        };
      })
      .catch((e) => {
        return {
          success: false,
          error: `${e}`,
        };
      });
  }

  getTableShipping(payload) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/shipments?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDetailBatch(id, payload) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // const id = payload.id;
    // delete payload.id;
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/shipments/${id}/orders?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDetailOrder(id) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // const id = payload.id;
    // delete payload.id;
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/orders/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getStatusShipping() {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/shipments/statuses`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getStatusOrder() {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/orders/statuses`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  paymentConfirmation(id: string) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    // const id = payload.id;
    // delete payload.id;
    return this.call(
      "POST",
      `flik-shipping/v1/seller-dash/shipments/${id}/pay`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  pickUpTime(id: string) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/shipments/pickup-range/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default ShippingModule;
