import { default as index4cPcrmmeBTMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/analisis/checkout/index.vue?macro=true";
import { default as indexazt0VlI7E4Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/analisis/pengiriman/index.vue?macro=true";
import { default as addA7ltaW45WwMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/invoice-link/add.vue?macro=true";
import { default as indexYKET5WdKfwMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/invoice-link/index.vue?macro=true";
import { default as _91id_93fS4REEnPoyMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/[id].vue?macro=true";
import { default as indexIiOMF8MsDnMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/index.vue?macro=true";
import { default as step_451rjG2IXodP6Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-1.vue?macro=true";
import { default as step_452jL87IrMnciMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-2.vue?macro=true";
import { default as step_453uFUZlALsfwMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-3.vue?macro=true";
import { default as step_454IJgSJPD7itMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-4.vue?macro=true";
import { default as successAWkZqPvHXEMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/success.vue?macro=true";
import { default as indexuEiqTiooHQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-password/index.vue?macro=true";
import { default as _91id_93TdFQ1OD40iMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/bantuan/[id].vue?macro=true";
import { default as indextlFNGbj4NxMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/bantuan/hubungi-kami/index.vue?macro=true";
import { default as indextm7f7FunHCMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/bantuan/index.vue?macro=true";
import { default as indexuLz9aD9kTnMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-1/index.vue?macro=true";
import { default as indexZWxqVpjLMAMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-2/index.vue?macro=true";
import { default as indexv8ZBd2xzFmMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-3/index.vue?macro=true";
import { default as indexZnAUshdIyLMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-4/index.vue?macro=true";
import { default as indexGCRyWVKo8IMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/email-verification/index.vue?macro=true";
import { default as _91id_93M4pOtmJVFPMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/post-purchase-offer/[id].vue?macro=true";
import { default as add7JldRvpvx2Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/post-purchase-offer/add.vue?macro=true";
import { default as index98wJr2TxURMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/post-purchase-offer/index.vue?macro=true";
import { default as _91id_93WvHetu4JJKMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/promosi/[id].vue?macro=true";
import { default as add4VlQyZlsjpMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/promosi/add.vue?macro=true";
import { default as indexSaypT7HDXlMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/promosi/index.vue?macro=true";
import { default as indexg52qxebc31Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/index.vue?macro=true";
import { default as _91id_935IfMQjzc8oMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/customer/[id].vue?macro=true";
import { default as indexw5xZLUzOb1Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/customer/index.vue?macro=true";
import { default as indexWhpx9Z4rHQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/pencairan-checkout/index.vue?macro=true";
import { default as indexJJka1OVxYCMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/pengiriman/index.vue?macro=true";
import { default as index7BrJPbZ0zfMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/transaksi/index.vue?macro=true";
import { default as indexRW2GmpIwmQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/login/index.vue?macro=true";
import { default as index9Rk8qfcGmkMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/logout/index.vue?macro=true";
import { default as indextbrBZ7y86QMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/lupa-password/index.vue?macro=true";
import { default as indexmNScwsrCP9Meta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-1/index.vue?macro=true";
import { default as indexIU4XXmWx0kMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-2/index.vue?macro=true";
import { default as indexGyp3nettfEMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-3/index.vue?macro=true";
import { default as indexaMaOrH7vlQMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-4/index.vue?macro=true";
import { default as _91id_93MYglYwvDiXMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/order/[id].vue?macro=true";
import { default as indexmReZZmo4kjMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/otp-email/index.vue?macro=true";
import { default as indexby9V24uHMVMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/otp/index.vue?macro=true";
import { default as indexFYPYtcKsijMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/ads-checkout/index.vue?macro=true";
import { default as indexItNAlkvo0wMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/metode-pembayaran-checkout/index.vue?macro=true";
import { default as indexBmSpXMKCZhMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/metode-pengiriman-checkout/index.vue?macro=true";
import { default as indexZwq3qMn7XjMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/profil/index.vue?macro=true";
import { default as _91id_93Yr1XQYUd7bMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/warehouse/[id].vue?macro=true";
import { default as addYe5FY64pmcMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/warehouse/add.vue?macro=true";
import { default as indexzJjtyeLCBxMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/warehouse/index.vue?macro=true";
import { default as indexsazUKv0mpsMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/register/index.vue?macro=true";
import { default as indexImvBkpEbYGMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/Saldo/index.vue?macro=true";
import { default as _91key_93kicI8ygeyPMeta } from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/verify/[key].vue?macro=true";
export default [
  {
    name: "analisis-checkout",
    path: "/analisis/checkout",
    meta: index4cPcrmmeBTMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/analisis/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "analisis-pengiriman",
    path: "/analisis/pengiriman",
    meta: indexazt0VlI7E4Meta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/analisis/pengiriman/index.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-invoice-link-add",
    path: "/atur-order/invoice-link/add",
    meta: addA7ltaW45WwMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/invoice-link/add.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-invoice-link",
    path: "/atur-order/invoice-link",
    meta: indexYKET5WdKfwMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/invoice-link/index.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim-id",
    path: "/atur-order/pengirim/:id()",
    meta: _91id_93fS4REEnPoyMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/[id].vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim",
    path: "/atur-order/pengirim",
    meta: indexIiOMF8MsDnMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/index.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim-step-1",
    path: "/atur-order/pengirim/step-1",
    meta: step_451rjG2IXodP6Meta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-1.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim-step-2",
    path: "/atur-order/pengirim/step-2",
    meta: step_452jL87IrMnciMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-2.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim-step-3",
    path: "/atur-order/pengirim/step-3",
    meta: step_453uFUZlALsfwMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-3.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim-step-4",
    path: "/atur-order/pengirim/step-4",
    meta: step_454IJgSJPD7itMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/step-4.vue").then(m => m.default || m)
  },
  {
    name: "atur-order-pengirim-success",
    path: "/atur-order/pengirim/success",
    meta: successAWkZqPvHXEMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-order/pengirim/success.vue").then(m => m.default || m)
  },
  {
    name: "atur-password",
    path: "/atur-password",
    meta: indexuEiqTiooHQMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/atur-password/index.vue").then(m => m.default || m)
  },
  {
    name: "bantuan-id",
    path: "/bantuan/:id()",
    meta: _91id_93TdFQ1OD40iMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/bantuan/[id].vue").then(m => m.default || m)
  },
  {
    name: "bantuan-hubungi-kami",
    path: "/bantuan/hubungi-kami",
    meta: indextlFNGbj4NxMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/bantuan/hubungi-kami/index.vue").then(m => m.default || m)
  },
  {
    name: "bantuan",
    path: "/bantuan",
    meta: indextm7f7FunHCMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/bantuan/index.vue").then(m => m.default || m)
  },
  {
    name: "completing-step-1",
    path: "/completing/step-1",
    meta: indexuLz9aD9kTnMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-1/index.vue").then(m => m.default || m)
  },
  {
    name: "completing-step-2",
    path: "/completing/step-2",
    meta: indexZWxqVpjLMAMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-2/index.vue").then(m => m.default || m)
  },
  {
    name: "completing-step-3",
    path: "/completing/step-3",
    meta: indexv8ZBd2xzFmMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-3/index.vue").then(m => m.default || m)
  },
  {
    name: "completing-step-4",
    path: "/completing/step-4",
    meta: indexZnAUshdIyLMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/completing/step-4/index.vue").then(m => m.default || m)
  },
  {
    name: "EmailVerification",
    path: "/email-verification",
    meta: indexGCRyWVKo8IMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/email-verification/index.vue").then(m => m.default || m)
  },
  {
    name: "fitur-tambahan-post-purchase-offer-id",
    path: "/fitur-tambahan/post-purchase-offer/:id()",
    meta: _91id_93M4pOtmJVFPMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/post-purchase-offer/[id].vue").then(m => m.default || m)
  },
  {
    name: "fitur-tambahan-post-purchase-offer-add",
    path: "/fitur-tambahan/post-purchase-offer/add",
    meta: add7JldRvpvx2Meta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/post-purchase-offer/add.vue").then(m => m.default || m)
  },
  {
    name: "fitur-tambahan-post-purchase-offer",
    path: "/fitur-tambahan/post-purchase-offer",
    meta: index98wJr2TxURMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/post-purchase-offer/index.vue").then(m => m.default || m)
  },
  {
    name: "fitur-tambahan-promosi-id",
    path: "/fitur-tambahan/promosi/:id()",
    meta: _91id_93WvHetu4JJKMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/promosi/[id].vue").then(m => m.default || m)
  },
  {
    name: "fitur-tambahan-promosi-add",
    path: "/fitur-tambahan/promosi/add",
    meta: add4VlQyZlsjpMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/promosi/add.vue").then(m => m.default || m)
  },
  {
    name: "fitur-tambahan-promosi",
    path: "/fitur-tambahan/promosi",
    meta: indexSaypT7HDXlMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/fitur-tambahan/promosi/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexg52qxebc31Meta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "laporan-customer-id",
    path: "/laporan/customer/:id()",
    meta: _91id_935IfMQjzc8oMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/customer/[id].vue").then(m => m.default || m)
  },
  {
    name: "laporan-customer",
    path: "/laporan/customer",
    meta: indexw5xZLUzOb1Meta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/customer/index.vue").then(m => m.default || m)
  },
  {
    name: "laporan-pencairan-checkout",
    path: "/laporan/pencairan-checkout",
    meta: indexWhpx9Z4rHQMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/pencairan-checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "laporan-pengiriman",
    path: "/laporan/pengiriman",
    meta: indexJJka1OVxYCMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/pengiriman/index.vue").then(m => m.default || m)
  },
  {
    name: "laporan-transaksi",
    path: "/laporan/transaksi",
    meta: index7BrJPbZ0zfMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/laporan/transaksi/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexRW2GmpIwmQMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "lupa-password",
    path: "/lupa-password",
    meta: indextbrBZ7y86QMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/lupa-password/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-1",
    path: "/onboarding/step-1",
    meta: indexmNScwsrCP9Meta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-1/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-2",
    path: "/onboarding/step-2",
    meta: indexIU4XXmWx0kMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-2/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-3",
    path: "/onboarding/step-3",
    meta: indexGyp3nettfEMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-3/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-4",
    path: "/onboarding/step-4",
    meta: indexaMaOrH7vlQMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/onboarding/step-4/index.vue").then(m => m.default || m)
  },
  {
    name: "order-id",
    path: "/order/:id()",
    meta: _91id_93MYglYwvDiXMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "otp-email",
    path: "/otp-email",
    meta: indexmReZZmo4kjMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/otp-email/index.vue").then(m => m.default || m)
  },
  {
    name: "otp",
    path: "/otp",
    meta: indexby9V24uHMVMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/otp/index.vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-ads-checkout",
    path: "/pengaturan/ads-checkout",
    meta: indexFYPYtcKsijMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/ads-checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-metode-pembayaran-checkout",
    path: "/pengaturan/metode-pembayaran-checkout",
    meta: indexItNAlkvo0wMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/metode-pembayaran-checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-metode-pengiriman-checkout",
    path: "/pengaturan/metode-pengiriman-checkout",
    meta: indexBmSpXMKCZhMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/metode-pengiriman-checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-profil",
    path: "/pengaturan/profil",
    meta: indexZwq3qMn7XjMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/profil/index.vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-warehouse-id",
    path: "/pengaturan/warehouse/:id()",
    meta: _91id_93Yr1XQYUd7bMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/warehouse/[id].vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-warehouse-add",
    path: "/pengaturan/warehouse/add",
    meta: addYe5FY64pmcMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/warehouse/add.vue").then(m => m.default || m)
  },
  {
    name: "pengaturan-warehouse",
    path: "/pengaturan/warehouse",
    meta: indexzJjtyeLCBxMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/pengaturan/warehouse/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indexsazUKv0mpsMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "Saldo",
    path: "/Saldo",
    meta: indexImvBkpEbYGMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/Saldo/index.vue").then(m => m.default || m)
  },
  {
    name: "verify-key",
    path: "/verify/:key()",
    meta: _91key_93kicI8ygeyPMeta || {},
    component: () => import("/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/pages/verify/[key].vue").then(m => m.default || m)
  }
]