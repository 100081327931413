export default defineNuxtRouteMiddleware((route) => {
  if (route.path !== "/" && route.path.endsWith("/index.html")) {
    const { path, query, hash } = route;
    const nextPath = path.replace("/index.html", "") || "/";
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute);
  } else if (route.path !== "/" && !route.path.endsWith("/")) {
    const { path, query, hash } = route;
    const nextPath = `${path}/`;
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute);
  }
});
