// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { toIsoLocalTime } from "../../composables/helper";
import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class ManualModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getTable(payload) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${CHECKOUTSVC}/v1/merchant-dashboard/manual-orders?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getCourierList() {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/shipments/courier-services`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getShopperByPhone(phone: string) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const phoneEncoded = encodeURIComponent(phone);
    return this.call(
      "GET",
      `${SHOPPERSVC}/v1/seller-dash/shoppers?search_phone=${phoneEncoded}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  createShopper(payload) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${SHOPPERSVC}/v1/seller-dash/shoppers`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  updateShopper(payload, id) {
    const { SHOPPERSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${SHOPPERSVC}/v1/seller-dash/shoppers/${id}`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  createOrder(payload) {
    const { CHECKOUTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${CHECKOUTSVC}/v1/merchant-dashboard/manual-orders`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  getRates(payload) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/couriers?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getPickUpTime(service_id: string) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const date = toIsoLocalTime(new Date());
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/couriers/pickup-times/${service_id}?time=${date}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  checkOrderRate(payload) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `flik-shipping/v1/seller-dash/shipments/orders/rate`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  checkAvailableSender(payload) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `flik-shipping/v1/seller-dash/shipments/check-availability`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  createShippingOrder(payload) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `flik-shipping/v1/seller-dash/shipments`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  getSummaryShipping(shipment_id) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/shipments/${shipment_id}/summary`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getDurations(courier_service_id: string) {
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `flik-shipping/v1/seller-dash/couriers/courier-services/${courier_service_id}/cod-fees`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default ManualModule;
