import { defineNuxtPlugin, useRuntimeConfig } from "nuxt/app";
// 3rd's
import { $fetch, type FetchOptions } from "ofetch";

// locals
import MerchantModule from "../repository/modules/merchant";
import ReportModule from "../repository/modules/report";
import PromotionModule from "../repository/modules/promotion";
import PPOModule from "../repository/modules/ppo";
import WarehouseModule from "../repository/modules/warehouse";
import ManualModule from "../repository/modules/manual";
import PaymentModule from "../repository/modules/payment";
import ConfigModule from "../repository/modules/config";
import ShippingModule from "../repository/modules/shipping";
import WalletModule from "../repository/modules/wallet";
import AnalyticsModule from "../repository/modules/analytics";

interface IApiInstance {
  merchant: MerchantModule;
  report: ReportModule;
  promotion: PromotionModule;
  ppo: PPOModule;
  warehouse: WarehouseModule;
  manual: ManualModule;
  payment: PaymentModule;
  config: ConfigModule;
  shipping: ShippingModule;
  wallet: WalletModule;
  analytics: AnalyticsModule;
}

declare module "nuxt/app" {
  interface NuxtApp {
    $api: IApiInstance;
  }
}

declare module "nuxt/app" {
  interface NuxtApp {
    $api: IApiInstance;
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const fetchOptions: FetchOptions = {
    baseURL: config.public.API_URL as string,
  };

  // Create a new instance of $fecther with custom option
  const apiFecther = $fetch.create(fetchOptions);

  // An object containing all repositories we need to expose
  const modules: IApiInstance = {
    merchant: new MerchantModule(apiFecther),
    report: new ReportModule(apiFecther),
    promotion: new PromotionModule(apiFecther),
    ppo: new PPOModule(apiFecther),
    warehouse: new WarehouseModule(apiFecther),
    manual: new ManualModule(apiFecther),
    payment: new PaymentModule(apiFecther),
    config: new ConfigModule(apiFecther),
    shipping: new ShippingModule(apiFecther),
    wallet: new WalletModule(apiFecther),
    analytics: new AnalyticsModule(apiFecther),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
